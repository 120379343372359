import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NavParams} from "@ionic/angular";
import {ProductDeliveryDto} from "../../../gateways/dtos/product-delivery-dto";
import {UsersService} from "../../../gateways/services/usersServices/users.service";

@Component({
  selector: 'app-popover-list-tube',
  templateUrl: './popover-list-tube.component.html',
  styleUrls: ['./popover-list-tube.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PopoverListTubeComponent implements OnInit {



public listProduct : Array<ProductDeliveryDto> = [];
public listProductAll : Array<ProductDeliveryDto> = [];
public search : boolean = false;

  constructor(
      public navParams: NavParams,
     public  usersService: UsersService
      ) {


   this.listProduct = navParams.get('listTube');
   this.listProductAll = navParams.get('listTube');
  }

  ngOnInit() {

  }
  
  filter(value){
      value.detail.value == '' ? this.search = false : this.search = true ;

      this.listProduct = this.listProductAll;
      this.listProduct = this.listProduct.filter(x => x.name.search(value.detail.value) != -1)
  }

  getUser(userId){
      // console.log("this.listProduct.length",this.listProduct.length);
      const findUser = this.usersService.users.findIndex(x => x.id == userId);
      if(this.listProduct.length >= 1){ return 'expediteur : '+ this.usersService.users[findUser].lastName + ' ' +this.usersService.users[findUser].firstName }
  }

}
