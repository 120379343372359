import{ MomentPipe} from "./moment.pipe";
import {NgModule} from "@angular/core";
import { GetUserPipe } from './get-user.pipe';
import { GetSitePipe } from './get-site.pipe';
import {OrthographePipe} from "./orthographe.pipe";
import { ConvertTypeContactPipe } from './convert-type-contact.pipe';
import { CalculateDurationPipe } from './calculate-duration.pipe';



@NgModule({
    imports: [],
    exports: [MomentPipe, GetUserPipe, GetSitePipe, OrthographePipe, ConvertTypeContactPipe, CalculateDurationPipe],
    declarations: [MomentPipe, GetUserPipe, GetSitePipe, OrthographePipe, ConvertTypeContactPipe, CalculateDurationPipe]
})
export class PipeModule {

}
