import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'calculateDuration'
})
export class CalculateDurationPipe implements PipeTransform {

  transform(dateStart: string, dateEnd: string) {
    let start = parseInt(moment(dateStart).format('X'));
    let end = parseInt(moment(dateEnd).format('X'));
    let dif = end-start;
    return moment.unix(dif).format('HH:mm');
  }

}
