import {Injectable} from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import {Router} from "@angular/router";
import {Observable, throwError} from 'rxjs';
import jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import {catchError} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class SDHttpInterceptorService implements HttpInterceptor {

	constructor(
		private router: Router
	) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!window.navigator.onLine) {
			// if (this.router.url != "/dash-on-site" && this.router.url.search("/dashboard") == -1) {
			// 	alert('Pas de connexion internet. Veuillez-vous reconnecter.');
			// }
			return throwError(new HttpErrorResponse({error: 'Internet is required.'}));
		} else {
			let authReq = req;
			if (localStorage.getItem('id_token') != null) {
				if (!this.isTokenValid()) {
					this.router.navigate(['/login'], {replaceUrl: true});
				} else {
					authReq = req.clone({
						headers: new HttpHeaders({
							'Content-Type': 'application/json',
							Accept: 'application/json',
							Authorization: 'Bearer ' + localStorage.getItem('id_token')
						})
					});
				}
			}

			return next.handle(authReq)
				.pipe(catchError((err: HttpErrorResponse) => {
					let errorMsg = '';
					if (err.error instanceof ErrorEvent) {
						errorMsg = err.error.message;
					}
					return throwError(errorMsg);
				}));
		}
	}

	isTokenValid(): boolean {
		let decodedJwt: any = jwt_decode(localStorage.getItem('id_token'));
		
		let dateExpMin = moment.unix(decodedJwt.exp);
		return moment(dateExpMin).isAfter(moment());
	}
}
