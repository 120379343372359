import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
	name: 'moment'
})
export class MomentPipe implements PipeTransform {
	
	transform(dateUtc: string, format: string, utc?: number) {
		
		return moment(dateUtc).format(format);
	}
	
}
