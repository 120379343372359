import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'orthographe'
})
export class OrthographePipe implements PipeTransform {
	
	transform(text: string): unknown {
		let textCorrection: string;
		
		switch (text) {
			case 'Site expediteur':
				textCorrection = 'Site expéditeur';
				break;
			
			case 'Rammassé à':
				textCorrection = 'Ramassée à';
				break;
			
			case 'Site de reception':
				textCorrection = 'Site de réception';
				break;
			
			case 'Livré à':
				textCorrection = 'Livrée à';
				break;
		}
		return textCorrection;
	}
	
}
