import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertTypeContact'
})
export class ConvertTypeContactPipe implements PipeTransform {

  transform(value: string): string {

    switch (value) {
      case 'PRO': return 'professionnel';
      case 'PERSO': return 'personnel';
    }
  }

}
