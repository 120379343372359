import { Pipe, PipeTransform } from '@angular/core';
import {SiteDto} from "../gateways/dtos/site-dto";

@Pipe({
  name: 'getSite'
})
export class GetSitePipe implements PipeTransform {

  transform(siteId: number, allSites: Array<SiteDto>){

    const findSite = allSites.findIndex(x => x.id == siteId);

    if( findSite != -1){
      return allSites[findSite].name;
    }else{
      return null;
    }
  }

}
