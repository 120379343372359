import {Injectable} from '@angular/core';
import {UserDto} from "../../dtos/userDto";
import {RootService} from "../root.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class UsersService extends RootService {
	users: Array<UserDto> = [];
	
	constructor(public http: HttpClient) {
		super(http);
		let usersStorage = JSON.parse(localStorage.getItem('users'));
		if (this.users.length == 0 && usersStorage) {
			this.users = usersStorage;
		}
	}
	
	getUserById(userId: number) {
		return new Promise((resolve) => {
			resolve(this.users[this.users.findIndex(x => x.id == userId)]);
		})
		
	}
	
	getUserByLogin(userLogin: string) {
		return this.users[this.users.findIndex(x => x.login == userLogin)];
	}
	
	getUserContactByUserId(userId: number) {
		return this.http.get(this.URL + 'user-contacts/userId/' + userId);
	}
}
